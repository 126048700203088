<template>
	<div class="h-full">
		<WwModal
			id="loginModal"
			:modal-id="LOGIN_MODAL"
			:header-offset="115"
			max-width="max-w-xl"
			modal-padding="p-3"
			hide-footer
		>
			<template #body>
				<div class="overflow-hidden text-center">
					<WwTabs @selectTab="setActiveTab">
						<WwTab
							:is-active="showLoginTab"
							:name="LOGIN_TAB"
						>
							<LoginForm
								:is-modal="true"
							/>
						</WwTab>
						<WwTab
							:is-active="showSignUpTab"
							:name="SIGN_UP_TAB"
						>
							<RegisterForm
								:is-modal="true"
							/>
						</WwTab>
					</WwTabs>
				</div>
			</template>
		</WwModal>
	</div>
</template>

<script async>
import WwTab from '@/components/UI/tabs/WwTab.vue'
import WwTabs from '@/components/UI/tabs/WwTabs.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { LOGIN_MODAL } from '@/constants/modal/names.js'
import { LOGIN_TAB, SIGN_UP_TAB } from '@/constants/tabs/names.js'

export default {
	components: {
		LoginForm: () => import('@/components/multiUse/LoginForm.vue'),
		RegisterForm: () => import('@/components/multiUse/RegisterForm.vue'),
		WwModal,
		WwTab,
		WwTabs
	},
	data() {
		return {
			LOGIN_MODAL,
			LOGIN_TAB,
			SIGN_UP_TAB,
			activeTab: LOGIN_TAB
		}
	},
	computed: {
		showLoginTab() {
			return this.activeTab === LOGIN_TAB
		},
		showSignUpTab() {
			return this.activeTab === SIGN_UP_TAB
		}
	},
	methods: {
		setActiveTab(tab) {
			this.activeTab = tab
		}
	}
}
</script>
